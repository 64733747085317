<div class="fullscreen" *ngIf="fullscreen">
    <h1
        class="text-center titleContainer d-flex justify-content-between align-items-center"
    >
        <a mat-button (click)="toggleFullscreen()" class="posX">
            <fa-icon [icon]="faTimes"></fa-icon>
        </a>
        <span id="titel">Telefoonboek</span>
        <div class="search_bar d-flex">
            <input
                #content
                id="search"
                class="typing flex-grow-1"
                type="text"
                placeholder="Zoeken"
                (keyup)="onSelect(content.value)"
                (keyup)="zoekResult()"
            />
            <a mat-button class="button">
                <fa-icon
                    [icon]="faSearch"
                    (click)="toggleSearch()"
                    matTooltip="klik om te zoeken"
                    matTooltipPosition="left"
                ></fa-icon>
            </a>
        </div>
    </h1>
    <div class="content d-flex">
        <ul class="w-100 justify-content-center align-items-center">
            <li
                *ngFor="let cat of oneCategory; let i = index"
                class="card col-lg-4 col-12 mx-auto"
            >
                <!-- Hier worden de rijen gefilterd per category, en ze worden maar 1 keer weergeven. -->
                <div
                    (click)="toggleCollapse(i)"
                    (click)="DropDown(i)"
                    class="card-body"
                >
                    <!-- Hier staan de categorieën in de button, als je klikt de organisaties van de bijbehorende category weergeven -->
                    <span>
                        <div *ngIf="!categoryStatus[i]">
                            <fa-icon [icon]="faAngleRight"></fa-icon> {{ cat }}
                            <!-- Als decategory NIET Open staat is het icoontje een angle naar rechts -->
                        </div>
                        <div *ngIf="categoryStatus[i]">
                            <fa-icon [icon]="faAngleDown"></fa-icon> {{ cat }}
                            <!-- Als decategory WEL Open staat is het icoontje een angle naar links -->
                        </div>
                    </span>
                </div>
                <div
                    id="{{ 'filterContacts' + i }}"
                    class="filterContactsClass"
                >
                    <!-- hier worden de conacten gefilterd en bij de goede organisatie neergezet -->

                    <div *ngFor="let con of sortFilterContacts[i]">
                        <a class="filterContacts" (click)="contact(con)"
                            ><fa-icon
                                *ngIf="con.type_id === 1; else mail"
                                class="iconPos2"
                                [icon]="faPhoneAlt"
                            ></fa-icon>
                            <ng-template #mail>
                                <fa-icon
                                    class="iconPos3"
                                    [icon]="faEnvelope"
                                ></fa-icon>
                            </ng-template>
                            <div *ngIf="con.category_id === 21">
                                {{ con.name }}
                            </div>
                            {{ con.organisation }} <br />
                            {{ con.value }} <br /><br />
                            {{ con.notes }}</a
                        >
                        <hr />
                    </div>

                    <!-- hier word de organisatie / contactpersoon weergeven per correcte category -->
                </div>
            </li>
        </ul>
    </div>
</div>
