<div class="fullscreen" *ngIf="calamityService.fullscreen">
    <div class="justify-content-center">
        <div *ngIf="!screenService.screen">
            <h1
                class="titleContainer justify-content-between text-center align-items-center"
            >
                <a mat-button (click)="toggleFullscreen()" class="posX">
                    <fa-icon [icon]="faTimes"></fa-icon>
                </a>
                <span>Calamiteiten</span>
            </h1>

            <div
                class="alert alert-info"
                role="alert"
                *ngIf="calamityService.empty"
            >
                Op deze locatie is geen informatie beschikbaar.
            </div>

            <ul *ngIf="!calamityService.empty">
                <li
                    class="calamityContainer"
                    *ngFor="let c of calamityService.calamities"
                >
                    <button [routerLink]="[]" (click)="startCalamity(c)">
                        {{ c.name }}
                    </button>
                </li>
            </ul>
        </div>
        <div class="mh-100">
            <screen (close)="toggleCalamities()"></screen>
        </div>
        <a
            mat-button
            class="back"
            (click)="toggleCalamities()"
            *ngIf="!screenService.screen"
        >
            <fa-icon [icon]="faArrowLeft"></fa-icon> Back
        </a>
    </div>
</div>
