import {
    Component,
    OnInit,
    OnDestroy,
    signal,
    ChangeDetectionStrategy,
    effect,
    EffectRef,
    ChangeDetectorRef,
    ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
    AuthService,
    ClickService,
    ConfigService,
    DetailService,
    EditService,
    FeatureService,
    HistoryService,
    MapService,
    LegendService,
    LayerService,
    InteractionService,
    SidenavService,
    GridService,
    CalamityService
} from 'app/_services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserManualDialogComponent } from '../_dialogs/user-manual/user-manual.dialog';

import { environment } from 'environments/environment';
import {
    faAngleLeft,
    faFilter,
    faGlobe,
    faBars,
    faBookOpen
} from '@fortawesome/free-solid-svg-icons';
import { register } from 'ol/proj/proj4';
import Projection from 'ol/proj/Projection';
import { get as getProjection, getTransform } from 'ol/proj';
import proj4 from 'proj4';
import Map from 'ol/Map';
import { defaults, DragAndDrop } from 'ol/interaction';
import ScaleLine from 'ol/control/ScaleLine';
import View from 'ol/View';
import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style.js';
import { defaults as controlDefaults } from 'ol/control';
import * as OlLayers from 'ol/layer';
import * as OlSource from 'ol/source';
import { default as OlEvent } from 'ol/events/Event';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import {
    getWidth,
    getTopLeft,
    applyTransform,
    boundingExtent,
    Extent
} from 'ol/extent';
import { GPX, GeoJSON, IGC, KML, TopoJSON, MVT } from 'ol/format';
import * as Format from 'ol/format';
import { bbox as bboxStrategy } from 'ol/loadingstrategy';
import { Subscription, fromEvent } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from 'app/_dialogs';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import * as localforage from 'localforage';
import { FormService } from 'app/_services/form.service';
import { MapEvent } from 'ol';
import { createXYZ } from 'ol/tilegrid.js';
import { tile as tileStrategy } from 'ol/loadingstrategy.js';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
    selector: 'cook',
    templateUrl: 'map.component.html',
    styleUrls: ['map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent implements OnInit, OnDestroy {
    readonly faAngleLeft = faAngleLeft;
    readonly faFilter = faFilter;
    readonly faGlobe = faGlobe;
    readonly faBookOpen = faBookOpen;
    readonly faBars = faBars;

    @ViewChild(MatDrawer) readonly sidenavElement: MatDrawer;
    // mattabgroup
    @ViewChild(MatTabGroup) readonly tabGroup: MatTabGroup;

    _enableLayerSubscription: Subscription;

    environment = environment;

    // Drag and Drop
    private dragAndDropInteraction: DragAndDrop;

    // Grid
    readonly infoClick = signal(false);

    // loading
    readonly loading = signal(false);
    readonly showContent = signal(false);
    private readonly configSubscription: EffectRef;
    // The maps target
    private readonly target = 'cook_map';

    private tabChangeSubscription: Subscription;

    constructor(
        readonly editService: EditService,
        readonly authService: AuthService,
        readonly configService: ConfigService,
        readonly detailService: DetailService,
        readonly activatedRoute: ActivatedRoute,
        readonly sidenavService: SidenavService,
        readonly interactionService: InteractionService,
        private readonly http: HttpClient,
        private readonly dialog: MatDialog,
        private readonly snackBar: MatSnackBar,
        private readonly mapService: MapService,
        private readonly gridService: GridService,
        private readonly layerService: LayerService,
        private readonly clickService: ClickService,
        private readonly legendService: LegendService,
        private readonly formService: FormService,
        private readonly calamityService: CalamityService,
        private readonly featureService: FeatureService,
        private readonly historyService: HistoryService,
        private readonly cdr: ChangeDetectorRef
    ) {
        this.configSubscription = effect(
            () => {
                if (this.configService.config() && !this.mapService.map()) {
                    this.calamityService.allCalamities =
                        this.configService.config().calamities;
                    localforage.setItem(
                        'calamities',
                        this.configService.config().calamities
                    );

                    this.configure();
                }

                // this is to close the sidenav when switching configurations
                this.gridService.openStatus.set('closed');
            },
            { allowSignalWrites: true }
        );
    }

    ngOnInit(): void {
        this.gridService.openStatus.set('closed');

        if (this.configService.config() && !this.mapService.map()) {
            this.configure();
        } else {
            if (this.activatedRoute.snapshot.paramMap.get('public')) {
                this.configService.configLoading.set(true);
                environment.public = true;
                this.configService.loadPublicConfiguration(
                    this.activatedRoute.snapshot.paramMap.get('public')
                );
            } else {
                const sharedParam =
                    this.activatedRoute.snapshot.paramMap.get('shared');

                if (sharedParam) {
                    const paramId = Number(sharedParam);
                    if (!isNaN(paramId)) {
                        this.configService.configLoading.set(true);
                        this.configService.getConfigurationByParam(paramId);
                    } else {
                        console.error(
                            'The shared parameter is not a valid number'
                        );
                    }
                } else if (
                    this.authService.id() ||
                    localStorage.getItem('id') ||
                    environment.public
                ) {
                    this.configService.getConfiguration();
                }
            }
        }

        // this is for the backbutton on all mobile devices. It shows a confirm if you want to leave the page or not.
        if (window.innerWidth <= 1300) {
            history.pushState(undefined, undefined, '');
            fromEvent(window, 'popstate').subscribe(() => {
                history.go(1);
                document.getElementById('confirm-dialog').style.display =
                    'block';
            });
        }
        this.showContent.set(true);
    }

    ngOnDestroy(): void {
        this.configSubscription.destroy();
        this.configService.config.set(undefined);

        if (this._enableLayerSubscription) {
            this._enableLayerSubscription.unsubscribe();
        }

        if (this.tabChangeSubscription) {
            this.tabChangeSubscription.unsubscribe();
        }

        this.showContent.set(true);

        this.mapService?.map()?.removeInteraction(this.dragAndDropInteraction);

        this.mapService.map.set(undefined);
    }

    initMap(): void {
        // Clear existing map and target element
        this.mapService.map.set(undefined);

        const doc = document.getElementById(this.target);
        if (doc?.innerHTML) {
            doc.innerHTML = '';
        }

        // Set projection if defined in configuration
        if (this.configService.config().projection) {
            const projData = this.configService.config().projection;
            const newProjCode = 'EPSG:' + projData.code;

            proj4.defs(newProjCode, projData.proj4);
            register(proj4);

            this.mapService.projection = getProjection(newProjCode);
            const fromLonLat = getTransform(
                'EPSG:4326',
                this.mapService.projection
            );

            let worldExtent: Extent = [
                projData.bbox[1],
                projData.bbox[2],
                projData.bbox[3],
                projData.bbox[0]
            ];
            this.mapService.projection.setWorldExtent(worldExtent);

            // approximate calculation of projection extent,
            // checking if the world extent crosses the dateline
            if (projData.bbox[1] > projData.bbox[3]) {
                worldExtent = [
                    projData.bbox[1],
                    projData.bbox[2],
                    projData.bbox[3] + 360,
                    projData.bbox[0]
                ];
            }
            const extent = applyTransform(
                worldExtent,
                fromLonLat,
                undefined,
                8
            );
            this.mapService.projection.setExtent(extent);
        } else {
            // Use default projection if not defined in configuration
            this.mapService.projection = new Projection({
                code: 'EPSG:28992',
                units: 'm',
                extent: [
                    -285401.92, 22598.08, 595401.9199999999, 903401.9199999999
                ]
            });
        }

        // Define projections and references
        proj4.defs(
            'EPSG:28992',
            '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 ' +
                '+x_0=155000 +y_0=463000 +ellps=bessel' +
                '+towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 +units=m +no_defs'
        );
        proj4.defs('urn:x-ogc:def:crs:EPSG:28992', proj4.defs('EPSG:28992'));
        proj4.defs(
            'http://www.opengis.net/gml/srs/epsg.xml#28992',
            proj4.defs('EPSG:28992')
        ); // Used by geoserver
        proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
        register(proj4);

        // Create scaleline control
        this.mapService.scaleline.set(new ScaleLine());

        // Add scaleline control if enabled in configuration
        const controlsOptions =
            this.configService.config().options.scalebar !== false
                ? [this.mapService.scaleline()]
                : [];

        this.mapService.map.set(
            new Map({
                target: this.target,
                interactions: defaults({
                    pinchRotate:
                        this.configService.config().options.rotation !=
                        undefined
                            ? this.configService.config().options.rotation
                            : true,
                    altShiftDragRotate:
                        this.configService.config().options.rotation !=
                        undefined
                            ? this.configService.config().options.rotation
                            : true
                }),
                controls: controlDefaults({
                    zoom: false,
                    attributionOptions: {
                        collapsed: true
                    }
                }).extend(controlsOptions),
                view: new View({
                    minZoom: this.configService.config().minZoom
                        ? this.configService.config().minZoom
                        : 0,
                    maxZoom: this.configService.config().maxZoom
                        ? this.configService.config().maxZoom
                        : 23,
                    projection: this.mapService.projection,
                    center: [0, 0],
                    zoom: this.configService.config().minZoom
                        ? this.configService.config().minZoom
                        : 5
                })
            })
        );

        this.enableDragAndDrop();

        if (this.configService.config().options.legendOpen) {
            this.sidenavService.setWidth(380, 'px');
        }
    }

    createLayers(): void {
        // Reset the infoClick to false
        this.infoClick.set(false);

        this.interactionService.editLayers = [];

        const layers = [];
        const maps = this.configService.config().maps;

        if (maps) {
            Object.keys(maps).forEach(mapName => {
                const map = maps[mapName];

                if (map.source) {
                    layers.push(this.createLayer(map));
                } else if (map.maps && map.maps.length) {
                    map.maps.forEach(mapInGroup => {
                        mapInGroup.pivot.order =
                            map.pivot.order + mapInGroup.sort / 100;
                        layers.push(this.createLayer(mapInGroup));
                    });
                }
            });
        }

        // Sort the layers by order and add them to the map
        layers
            .sort((a, b) => a.get('order') - b.get('order'))
            .forEach(layer => {
                this.mapService.map().addLayer(layer);
            });

        // Dispatch change event on the map to make sure the layer is visible
        this.mapService.map().dispatchEvent(new OlEvent('change'));
    }

    /**
     * Replace unwanted characters in CQL
     * @param  cql Dirty CQL
     * @return     Clean CQL
     */
    urlEncodeCQL(cql): string {
        return cql
            .replace(/\s/g, '%20')
            .replace(/>/g, '%3E')
            .replace(/</g, '%3C');
    }

    // Creates an actual map on the OpenLayers map instance
    createLayer(map: any): any {
        // Modify the maps layers so they can be used on the new layer below
        const layers: string[] = [];
        const clickLayers: string[] = [];

        // Layers and groups need to be bind all together for sorting
        const { layers: mapLayers, layer_groups: mapLayerGroups } = map.source;
        const combined = [...mapLayers, ...mapLayerGroups].sort(
            (n1, n2) => n1.order + n2.order
        );

        combined.forEach(item => {
            if (item.layerName || item.layers) {
                if (item.layerName) {
                    // Enable all layers in groups used as layers
                    if (item.visible) layers.push(item.layerName);

                    // Use a separate array for clickable layers
                    if (item.click) clickLayers.push(item.layerName);
                } else {
                    item.layers
                        .sort((n1, n2) => n1.order - n2.order)
                        .forEach(l => {
                            if (l.name !== undefined) {
                                if (l.visible !== false) layers.push(l.name);
                                if (l.click) clickLayers.push(l.name);
                            }
                        });
                }
            } else {
                if (item.name != undefined) {
                    if (item.visible !== false) layers.push(item.name);
                    if (item.click) clickLayers.push(item.name);
                }
            }
        });

        // Create layer source
        let layerSource;
        let layer;
        let broken = false;

        switch (map?.type) {
            case 'Image':
                layerSource = this.createImageSource(map, layers);
                break;
            case 'Tile':
                layerSource = this.createTileSource(map, layers);
                break;
            case 'Vector':
                layerSource = this.createVectorSource(map, layers);
                break;
            case 'VectorTile':
                layerSource = this.createVectorTileSource(map, layers);
                break;
            case 'Heatmap':
                layerSource = this.createVectorSource(map, layers);
                break;
            case 'WebGLPoints':
                layerSource = this.createWebGLPointsSource(map, layers);
                map.styling = JSON.parse(map.styling);

                break;
            default:
                broken = true;
                console.error(`Invalid layer type: ${map?.type}`);
        }

        // Add attribution if it's present in the config
        if (map?.source.attributions) {
            layerSource.setAttributions(map?.source.attributions);
        }

        let featureType: string | undefined;
        let namespace: string | undefined;

        for (const { name, value } of map.source.params) {
            if (name === 'typename') {
                featureType = value;
            } else if (name === 'namespace') {
                namespace = value;
                // Remove the namespace from the parameters
                const index = map.source.params.indexOf({ name, value });
                map.source.params.splice(index, 1);
            }
        }

        layer = new OlLayers[map.type]({
            title: map.name,
            id: map.id,
            source: layerSource,
            visible: map.visible ?? true,
            maxResolution: map.maxResolution ?? undefined,
            minResolution: map.minResolution ?? undefined,
            opacity: +map.opacity || 1,
            click: map.click ?? undefined,
            radius: map.radius ?? 1,
            authorization: map.source.authorization ?? undefined,
            serverType: map.source.serverType ?? undefined,
            collapsed: map.collapsed ?? true,
            icon: map.icon ?? false,
            geometry: map.source.geometry ?? undefined,
            order: map.pivot.order,
            preload: map.preload ? Infinity : 1,
            broken: broken,
            create: map.create,
            featureType: featureType ?? undefined,
            namespace: namespace ?? undefined,
            grid: map.grid ? JSON.parse(map.grid) : undefined,
            clickLayers: clickLayers ?? undefined,
            //  For the heatmap
            blur: 100,
            weight: () => 100,
            style:
                map.type === 'WebGLPoints'
                    ? {
                          symbol: {
                              symbolType: 'circle',
                              size: [
                                  'interpolate',
                                  ['exponential', 2.5],
                                  ['zoom'],
                                  2,
                                  1,
                                  14,
                                  32
                              ],
                              color:
                                  map?.styling && map?.styling.color
                                      ? map?.styling.color
                                      : 'rgb(255, 172, 69)',
                              offset: [0, 0],
                              opacity: 0.95
                          }
                      }
                    : undefined
        });

        if (map.click) {
            // Enable the WFS click interaction, which will be handled by the feature service
            this.infoClick.set(true);
        }

        if (map.edit) {
            this.interactionService.editLayers.push(layer);
        }

        this.createLayerStyle(map, layer);

        if (map.pivot.background) {
            layer.set('background', map.pivot.background);
        }
        if (map.pivot.background_image) {
            layer.set('backgroundImage', map.pivot.background_image);
        }

        // Subscribe to changes in the "enable layer" event, which is used to turn layers on/off
        this._enableLayerSubscription =
            this.legendService.enableLayerChange.subscribe(layerName => {
                // If the layer is manually turned off, we can't know it
                if (map.name === layerName && !map.visible) {
                    // If the layer is off, turn it on and all of its sub-layers
                    this.legendService.toggleMap(map);
                    map.visible = true;

                    map.source.layers.forEach(l => {
                        if (!l.visible) {
                            this.legendService.toggleLayer(l, map);
                            l.visible = true;
                        }
                    });

                    map.source.layer_groups.forEach(g => {
                        if (g.visible) {
                            this.legendService.toggleLayer(g, map);
                            g.visible = true;
                        }
                    });
                }
            });

        return layer;
    }

    createImageSource(map, layers): any {
        // Set some default source parameters
        const params = {
            // Always set the layers
            LAYERS: layers,
            FORMAT: 'image/png',
            TRANSPARENT: true,
            VERSION: '1.1.1',
            TILED: false,
            STYLES: ''
        };

        // Override params if they are set
        map.source.params.forEach(p => {
            params[p.name] = p.value;
        });

        let layerSource;
        switch (map.source.type) {
            case 'ImageWMS':
                layerSource = new OlSource.ImageWMS({
                    url: map.source.url,
                    params,
                    crossOrigin: 'anonymous',
                    attributions: map.source.attributions,
                    serverType: map.source.serverType
                        ? map.source.serverType
                        : 'mapserver'
                });

                if (map.source.authorization) {
                    layerSource.setImageLoadFunction(async function (
                        image: any,
                        url: string
                    ) {
                        const headers = new Headers();
                        headers.append(
                            'Authorization',
                            map.source.authorization
                        );
                        const init = {
                            headers: headers,
                            method: 'GET'
                        };
                        const response = await fetch(url, init);
                        const imageData = await response.blob();
                        const imageElement =
                            image.getImage() as HTMLImageElement;
                        imageElement.src =
                            window.URL.createObjectURL(imageData);
                    });
                }

                break;
            case 'ImageArcGISRest':
                layerSource = new OlSource.ImageArcGISRest({
                    crossOrigin: 'anonymous',
                    url: map.source.url,
                    params
                });
                break;
            default:
                layerSource = new OlSource[map.source.type]({
                    crossOrigin: 'anonymous',
                    url: map.source.url,
                    params
                });
                break;
        }

        return layerSource;
    }

    createTileSource(map, layers): any {
        let params = {};

        let layerSource;
        switch (map.source.type) {
            case 'WMTS':
                // @shouldRemove this gives errors in vscode not looked in to it
                const projectionExtent = this.mapService.projection.getExtent();
                const size = getWidth(projectionExtent) / 256;

                const resolutions = new Array(16);
                const matrixIds = new Array(16);

                for (let z = 0; z < 17; ++z) {
                    // generate resolutions and matrixIds arrays for this WMTS
                    resolutions[z] = size / Math.pow(2, z);
                    matrixIds[z] = 'EPSG:28992:' + z;
                }

                let style = 'default';
                let format = 'image/png';
                map.source.params.forEach(p => {
                    switch (p.name) {
                        case 'style':
                            style = p.value;
                            break;
                        case 'format':
                            format = p.value;
                            break;
                    }
                });

                layerSource = new OlSource.WMTS({
                    crossOrigin: 'anonymous',
                    url: map.source.url,
                    matrixSet: 'EPSG:28992',
                    layer: layers[0],
                    style,
                    format,
                    // ratio: 1,
                    projection: this.mapService.projection,
                    tileGrid: new WMTSTileGrid({
                        origin: getTopLeft(projectionExtent),
                        resolutions: resolutions,
                        matrixIds: matrixIds
                        // tileSize: 256,
                        // sizes
                    })
                });

                // A wmts layer can only have 1 layer
                map.source.layers = [];
                map.source.layer_groups = [];
                break;
            case 'TileWMS':
                // Some default params
                params = {
                    // Always set the layers
                    LAYERS: layers,
                    FORMAT: 'image/png',
                    VERSION: '1.1.1',
                    TILED: false
                };

                // Override params if they are set
                map.source.params.forEach(p => {
                    params[p.name] = p.value;
                });

                layerSource = new OlSource.TileWMS({
                    crossOrigin: 'anonymous',
                    url: map.source.url,
                    params,
                    attributions: map.source.attributions,
                    serverType: map.source.serverType
                        ? map.source.serverType
                        : 'mapserver'
                });

                if (map.source.authorization) {
                    layerSource.setTileLoadFunction((tile, src, auth) => {
                        const xhr = new XMLHttpRequest();
                        xhr.open('GET', src);
                        xhr.setRequestHeader(
                            'Authorization',
                            map.source.authorization
                        );
                        xhr.withCredentials = true;
                        xhr.send();
                    });
                }

                break;
            case 'XYZ':
                let urlParams = '';
                map.source.params.forEach(p => {
                    urlParams += '&' + p.name + '=' + p.value;
                });

                layerSource = new OlSource.XYZ({
                    crossOrigin: 'anonymous',
                    url: map.source.url + urlParams
                });
                break;
            case 'OSM':
                // Default openlayers map
                layerSource = new OlSource.OSM();
                break;
            case 'TileArcGISRest':
                layerSource = new OlSource.TileArcGISRest({
                    crossOrigin: 'anonymous',
                    url: map.source.url,
                    params: {
                        LAYERS: layers,
                        VERSION: '1.1.1',
                        STYLES: ''
                    }
                });
                break;
            default:
                break;
        }

        return layerSource;
    }

    createVectorSource(map, layers): any {
        let wfsLoader;
        let layerSource;
        switch (map.source.type) {
            case 'Heatmap':
            case 'Vector':
                if (!map.source.format) {
                    map.source.format = 'GeoJSON';
                }

                // De if logica moet hier veel beter worden
                if (map.source.serverType) {
                    layerSource = new OlSource.Vector({
                        format: new Format[map.source.format](),
                        strategy: bboxStrategy
                    });

                    wfsLoader = function (
                        extent: any,
                        resolution: any,
                        projection: any
                    ) {
                        let url = map.source.url;
                        const source = arguments[0];

                        // Add params to request url
                        let params = '';
                        let cqlFilterParam = '';
                        for (const p of map.source.params) {
                            if (p.name === 'cql_filter') {
                                cqlFilterParam = '&cql_filter=' + p.value;
                            } else {
                                params += '&' + p.name + '=' + p.value;
                            }
                        }

                        url += this.urlEncodeCQL(cqlFilterParam);
                        if (source.get('cql')) {
                            // If a cql filter was set in the params, add any cql from the source to it, otherwise create a new cql_filter param
                            if (cqlFilterParam) {
                                url +=
                                    ' AND ' +
                                    this.urlEncodeCQL(source.get('cql'));
                            } else {
                                url +=
                                    '&cql_filter=' +
                                    this.urlEncodeCQL(source.get('cql'));
                            }
                        }

                        // Replace current_date in url with the actual date
                        const moment = require('moment');
                        url = url.replace(
                            'current_date',
                            moment().format('YYYY-MM-DD').toString()
                        );

                        // For the filters to work they need all features
                        // Bbox loading strategy cannot be used if there are filters
                        if (
                            cqlFilterParam === '' &&
                            !this.configService.config().tools?.filters
                        ) {
                            // Add the bboxStrategy
                            url += `&bbox=${resolution.join(',')},EPSG:28992`;
                        }

                        let headers = {};
                        if (map.source.authorization) {
                            headers = {
                                Authorization: map.source.authorization
                            };
                        }
                        return this.http
                            .get(url + params, {
                                headers,
                                responseType: 'json'
                            })
                            .subscribe(response => {
                                const features = new Format[
                                    map.source.format
                                ]().readFeatures(response, {
                                    featureProjection:
                                        this.mapService.projection
                                });
                                layerSource.addFeatures(features);
                            });
                    };

                    layerSource.setUrl(map.source.url);
                    layerSource.setLoader(wfsLoader.bind(this, layerSource));
                } else if (map.source.url) {
                    let params = '';
                    for (const p of map.source.params) {
                        params += '&' + p.name + '=' + p.value;
                    }

                    let headers = {};
                    if (map.source.authorization) {
                        headers = { Authorization: map.source.authorization };
                    }

                    layerSource = new OlSource.Vector({
                        format: new Format[map.source.format]()
                    });

                    this.http
                        .get(map.source.url + params, {
                            headers,
                            responseType: 'text'
                        })
                        .subscribe(response => {
                            const features = new Format[
                                map.source.format
                            ]().readFeatures(response, {
                                featureProjection: this.mapService.projection
                            });
                            layerSource.addFeatures(features);
                        });
                } else if (map.source.features) {
                    layerSource = new OlSource.Vector({
                        format: new GeoJSON(),
                        loader: extent => {
                            this.http
                                .get(
                                    `${environment.api_base_url}/tiles/features?filter=source_id=${map.source.id}&crs=28992&limit=5000`,
                                    {
                                        responseType: 'text'
                                    }
                                )
                                .subscribe(response => {
                                    const features = new GeoJSON().readFeatures(
                                        response
                                    );
                                    layerSource.addFeatures(features);
                                });
                        }
                    });
                }

                break;
            case 'Cluster':
                let source: any;
                if (map.source.authorization) {
                    wfsLoader = function (
                        extent: any,
                        resolution: any,
                        projection: any
                    ) {
                        const source = arguments[0];

                        if (arguments.length < 4) {
                            // error
                            console.error(
                                'Forgot to bind the source on the wfsLoader?'
                            );
                        }

                        // Add params to request url
                        let params = '';
                        let cqlFilterParam = '';
                        for (const p of map.source.params) {
                            if (p.name === 'cql_filter') {
                                cqlFilterParam = `&cql_filter=${p.value}`;
                            } else {
                                params += `&${p.name}=${p.value}`;
                            }
                        }

                        let url =
                            map.source.url + this.urlEncodeCQL(cqlFilterParam);
                        if (source.get('cql')) {
                            // If a cql filter was set in the params, add any cql from the source to it, otherwise create a new cql_filter param
                            if (cqlFilterParam) {
                                url +=
                                    ' AND ' +
                                    this.urlEncodeCQL(source.get('cql'));
                            } else {
                                url +=
                                    '&cql_filter=' +
                                    this.urlEncodeCQL(source.get('cql'));
                            }
                        }

                        // Replace current_date in url with the actual date
                        const moment = require('moment');
                        url = url.replace(
                            'current_date',
                            moment().format('YYYY-MM-DD').toString()
                        );

                        const headers = {
                            Authorization: map.source.authorization
                        };

                        return this.http
                            .get(url + params, {
                                headers,
                                responseType: 'json'
                            })
                            .subscribe(response => {
                                const features = new GeoJSON().readFeatures(
                                    response
                                );
                                source.addFeatures(features);
                            });
                    };

                    // Create a vector layer that's being used by the cluster layer
                    source = new OlSource.Vector({
                        format: new GeoJSON(),
                        wrapX: false
                    });

                    source.setLoader(wfsLoader.bind(this, source));
                }

                // Create clusters
                layerSource = new OlSource.Cluster({
                    distance: parseInt('40', 10),
                    source
                });

                this.interactionService.clusterClick.interaction =
                    this.mapService.map().on('singleclick', e => {
                        this.clickService.zoomToCluster(e.pixel);
                    });
                break;
            case 'EsriJSON':
                layerSource = new OlSource.Vector({
                    format: new Format['EsriJSON'](),
                    url: (extent, resolution, projection) => {
                        // ArcGIS Server only wants the numeric portion of the projection ID.
                        const srid = projection
                            .getCode()
                            .split(/:(?=\d+$)/)
                            .pop();

                        const url =
                            map.source.url +
                            layers[0] +
                            '/query/?f=json&' +
                            'returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=' +
                            encodeURIComponent(
                                '{"xmin":' +
                                    extent[0] +
                                    ',"ymin":' +
                                    extent[1] +
                                    ',"xmax":' +
                                    extent[2] +
                                    ',"ymax":' +
                                    extent[3] +
                                    ',"spatialReference":{"wkid":' +
                                    srid +
                                    '}}'
                            ) +
                            '&geometryType=esriGeometryEnvelope&inSR=' +
                            srid +
                            '&outFields=*' +
                            '&outSR=' +
                            srid;

                        return url;
                    },
                    strategy: tileStrategy(
                        createXYZ({
                            tileSize: 512
                        })
                    )
                });
            default:
                break;
        }

        return layerSource;
    }

    createVectorTileSource(map, layers): any {
        const projectionExtent = this.mapService.projection.getExtent();
        const projectionSize = getWidth(projectionExtent) / 256;
        const maxZoom = 17;

        const resolutions = new Array(maxZoom);
        const matrixIds = new Array(maxZoom);

        for (let z = 0; z < maxZoom; ++z) {
            resolutions[z] = projectionSize / Math.pow(2, z);
            matrixIds[z] = `EPSG:28992:${z}`;
        }

        let layerSource;

        switch (map.source.type) {
            case 'MVT':
                let url = map.source.url + '/';
                // btoa('?limit=500&filter=source_id=' + '1755')

                layerSource = new OlSource.VectorTile({
                    projection: this.mapService.projection,
                    format: new MVT(),
                    url: url
                    // tileGrid: new TileGrid({
                    //     resolutions,
                    //     extent: this.mapService.projection.getExtent(),
                    //     tileSize: 256
                    // }),
                });

                layerSource.setTileLoadFunction((tile, url) => {
                    tile.setLoader(function (extent, resolution, projection) {
                        fetch(url, { credentials: 'include' }).then(function (
                            response
                        ) {
                            response.arrayBuffer().then(function (data) {
                                const format = tile.getFormat();
                                const features = format.readFeatures(data, {
                                    extent: extent,
                                    featureProjection: projection
                                });
                                tile.setFeatures(features);
                            });
                        });
                    });
                });
                break;

            default:
                console.error(
                    `Unsupported vector tile format: ${map.source.format}`
                );
        }

        return layerSource;
    }

    createWebGLPointsSource(map, layers): any {
        const layerSource = new OlSource.Vector();

        if (map.source.geojson) {
            const features = new GeoJSON().readFeatures(map.source.geojson, {
                // dataProjection: 'EPSG:4326',
                featureProjection: this.mapService.projection
            });
            layerSource.addFeatures(features);
        }

        return layerSource;
    }

    createLayerStyle(map, layer): void {
        // select the style we want for a layer in the options and then it's loaded here
        if (
            (map.source.type === 'Vector' && map.type !== 'WebGLPoints') ||
            map.source.type === 'VectorTile' ||
            map.source.type === 'MVT' ||
            map.source.type === 'EsriJSON'
        ) {
            // If there is styling in the db override the hardcoded
            if (map.styling) {
                if (typeof map.styling === 'string') {
                    map.styling = JSON.parse(map.styling);
                }

                layer.setStyle((feature, resolution) => {
                    // @shouldRemove what does this
                    let featureText = f => '';

                    if (map.styling.labelKey) {
                        featureText = f => {
                            const string = f.get(map.styling.labelKey)
                                ? f.get(map.styling.labelKey)
                                : '';
                            if (typeof string === 'string') {
                                return string;
                            }
                            return '';
                        };
                    }
                    if (
                        map.styling.labelMaxRes &&
                        map.styling.labelMaxRes < resolution
                    ) {
                        featureText = f => '';
                    }

                    let fillColor: any;
                    let strokeColor: any;
                    if (map.styling.features) {
                        map.styling.features.forEach(f => {
                            if (feature.get(f.key)) {
                                f.props.forEach(p => {
                                    if (feature.get(f.key) === p.value) {
                                        fillColor = p.fillColor;
                                        strokeColor = p.fillColor;
                                    }
                                });
                            }
                        });
                    }

                    let style: any;
                    if (fillColor && strokeColor) {
                        style = new Style({
                            image: new Circle({
                                fill: new Fill({
                                    color: fillColor
                                }),
                                stroke: new Stroke({
                                    color: strokeColor,
                                    width: map.styling.lineWidth
                                        ? map.styling.lineWidth
                                        : 1.5
                                }),
                                radius: 5
                            }),
                            fill: new Fill({
                                color: fillColor
                            }),
                            stroke: new Stroke({
                                color: strokeColor,
                                width: map.styling.lineWidth
                                    ? map.styling.lineWidth
                                    : 1.5
                            }),
                            text: new Text({
                                text: featureText(feature),
                                textAlign: 'center',
                                offsetY: 40,
                                scale: 1.4,
                                stroke: new Stroke({
                                    color: '#ffffff',
                                    width: 3
                                })
                                // fill: new Fill({
                                // })
                            })
                        });
                    } else {
                        style = new Style({
                            image: new Circle({
                                fill: new Fill({
                                    color: map.styling.fillColor
                                        ? map.styling.fillColor
                                        : 'rgba(244, 148, 65, 0.3)'
                                }),
                                stroke: new Stroke({
                                    color: map.styling.color
                                        ? map.styling.color
                                        : '#f49441',
                                    width: map.styling.lineWidth
                                        ? map.styling.lineWidth
                                        : 1.5
                                }),
                                radius: 5
                            }),
                            fill: new Fill({
                                color: map.styling.fillColor
                                    ? map.styling.fillColor
                                    : 'rgba(244, 148, 65, 0.3)'
                            }),
                            stroke: new Stroke({
                                color: map.styling.color
                                    ? map.styling.color
                                    : '#f49441',
                                width: map.styling.lineWidth
                                    ? map.styling.lineWidth
                                    : 1.5
                            }),
                            text: new Text({
                                text: featureText(feature),
                                textAlign: 'center',
                                offsetY: 40,
                                scale: 1.4,
                                stroke: new Stroke({
                                    color: '#ffffff',
                                    width: 1.5
                                })
                                // fill: new Fill({
                                // })
                            })
                        });
                    }

                    return style;
                });
            } else {
                this.layerService.setStyle(layer);
            }
        }

        // @TODO a temporary style for the cluster layer, the style should be put in a different service or config file in the future
        if (map.source.type == 'Cluster') {
            const styleCache = {};

            layer.setStyle((feature: any) => {
                if (feature.get('features')) {
                    const size = feature.get('features').length;
                    let style = styleCache[size];
                    if (!style) {
                        style = new Style({
                            image: new Circle({
                                radius: 10,
                                stroke: new Stroke({
                                    color: '#fff'
                                }),
                                fill: new Fill({
                                    color: '#3399CC'
                                })
                            }),
                            text: new Text({
                                text: size.toString(),
                                fill: new Fill({
                                    color: '#fff'
                                })
                            })
                        });
                        styleCache[size] = style;
                    }

                    return style;
                }
            });
        }

        if (map.source.marker) {
            layer.setStyle(feature => {
                // Define a default marker color
                let marker_color = 'orange';

                // Return the proper feature style
                const style = new Style({
                    fill: new Fill({
                        color: 'rgba(0, 51, 255, 0.5)'
                    }),
                    stroke: new Stroke({
                        color: '#0033ff',
                        width: 5
                    }),
                    image: new Icon({
                        anchor: [0.5, 46],
                        opacity: 1,
                        anchorXUnits: 'fraction',
                        anchorYUnits: 'pixels',
                        src: `assets/img/icons/map-marker-${marker_color}.png`
                    })
                });

                return style;
            });
        }
    }

    // Configures some aspects of the application
    configure(): void {
        const extentParam = this.activatedRoute.snapshot.paramMap.get('extent');
        const sharedParam = this.activatedRoute.snapshot.paramMap.get('shared');

        this.configService.setTitle();

        // Reset everything
        this.layerService.layers = {
            drawingLayer: undefined,
            featureLayer: undefined,
            overlayLayer: undefined,
            outlineLayer: undefined,
            clusterLayer: undefined,
            markerLayer: undefined,
            measurementLayer: undefined,
            redliningLayer: undefined,
            dragAndDropLayer: undefined,
            overviewSelectedLayer: undefined,
            notificationLayer: undefined,
            createLayer: undefined,
            bufferLayer: undefined
        };

        this.sidenavService.tabIndex.set(0);
        this.editService.setfeatureType(undefined);
        this.featureService.clicks = undefined;

        if (this.configService.config().info) {
            if (
                this.configService.config().info.title ||
                this.configService.config().info.description
            ) {
                this.dialog.open(InfoDialogComponent, {
                    data: {
                        title: this.configService.config().info.title,
                        message: this.configService.config().info.description
                    }
                });
            }
        }

        // First, create the map
        this.initMap();

        // Start by loading the layers
        this.createLayers();

        let extent: Array<any>;

        // Override the config extent if we have an extent in the url
        if (extentParam) {
            const coordinatesArray = this.activatedRoute.snapshot.paramMap
                .get('extent')
                .split(',');
            const boundingExtent2 = [
                [+coordinatesArray[0], +coordinatesArray[1]],
                [+coordinatesArray[2], +coordinatesArray[3]]
            ];

            extent = boundingExtent(boundingExtent2);
            this.mapService
                .map()
                .getView()
                .fit(extent, this.mapService.map().get('size'));
        } else if (this.configService.config().extent) {
            if (this.configService.config().extent[0].length) {
                extent = [
                    this.configService.config().extent[0][0],
                    this.configService.config().extent[0][1],
                    this.configService.config().extent[1][0],
                    this.configService.config().extent[1][1]
                ];
            } else {
                extent = this.configService.config().extent;
            }

            this.mapService
                .map()
                .getView()
                .fit(extent, this.mapService.map().get('size'));
        }

        if (sharedParam) {
            // Not sure where this needs to be added
            this.http
                .get(environment.api_base_url + '/shared/' + sharedParam)
                .subscribe((res: any) => {
                    console.log(res);

                    const coordinatesArray = res.extent.split(',');
                    const boundingExtent2 = [
                        [+coordinatesArray[0], +coordinatesArray[1]],
                        [+coordinatesArray[2], +coordinatesArray[3]]
                    ];

                    extent = boundingExtent(boundingExtent2);
                    console.log(extent);
                    this.mapService
                        .map()
                        .getView()
                        .fit(extent, this.mapService.map().get('size'));
                });
        }

        const form_id =
            this.activatedRoute.snapshot.paramMap.get('form_value_id');

        if (
            form_id &&
            this.configService.config()?.forms?.length &&
            !environment.public
        ) {
            this.sidenavService.tabIndex.set(2);
        }

        // @TODO: Check why the signal loading is not working all the time. It has to do something with change detection
        this.mapService.map().on('loadstart', () => {
            this.loading.set(true);
        });

        this.mapService.map().on('loadend', () => {
            this.loading.set(false);
            this.cdr.detectChanges();
        });

        this.mapService.map().on('moveend', (event: MapEvent) => {
            const view = event.map.getView();
            const zoomLevel = view.getZoom();
            const resolution = view.getResolution();
            const center = view.getCenter();

            this.mapService.zoomLevel.set(zoomLevel);
            this.mapService.resolution.set(resolution);

            // prevent duplicate history entries
            if (
                this.configService.config().options.mapHistoryButtons &&
                center !== this.mapService.center()
            ) {
                this.historyService.updateHistory(view.calculateExtent());
            }

            this.mapService.center.set(center);
        });

        if (this.configService.config()?.forms?.length && !environment.public) {
            const form = this.configService.config()?.forms[0];
            this.formService.form = form;

            let headers = {};
            // if (map.source.authorization) {
            //     headers = { Authorization: map.source.authorization };
            // }

            let layerSource = new OlSource.Vector({
                format: new GeoJSON(),
                loader: extent => {
                    this.http
                        .get(
                            `${environment.api_base_url}/tiles/registers?filter=form_id=${form.id}&limit=1000`,
                            {
                                headers,
                                responseType: 'text'
                            }
                        )
                        .subscribe(response => {
                            const features = new GeoJSON().readFeatures(
                                response,
                                {
                                    featureProjection:
                                        this.mapService.projection
                                }
                            );
                            layerSource.addFeatures(features);
                        });
                }
            });

            const options: any = {
                title: form.name,
                // Make sure the form id doesn't collapse into map ids
                id: 'form_' + form.id,
                source: layerSource,
                visible: true
            };

            let formMap: any = new OlLayers['Vector'](options);

            if (typeof form.styling === 'string') {
                form.styling = JSON.parse(form.styling);
            }

            if (form.styling) formMap.setStyle((feature, resolution) => {
                let fillColor: any;
                let strokeColor: any;

                if (form.styling.features) {
                    form.styling.features.forEach(f => {
                        if (feature.getProperties().value[f.key]) {
                            f.props.forEach(p => {
                                if (
                                    feature.getProperties().value[f.key] ===
                                    p.value
                                ) {
                                    fillColor = p.fillColor;
                                    strokeColor = p.fillColor;
                                }
                            });
                        }
                    });
                }

                if (fillColor && strokeColor) {
                    return new Style({
                        image: new Circle({
                            fill: new Fill({
                                color: fillColor
                            }),
                            stroke: new Stroke({
                                color: strokeColor,
                                width: form.styling.lineWidth
                                    ? form.styling.lineWidth
                                    : 3
                            }),
                            radius: 5
                        }),
                        fill: new Fill({
                            color: fillColor
                        }),
                        stroke: new Stroke({
                            color: strokeColor,
                            width: form.styling.lineWidth
                                ? form.styling.lineWidth
                                : 3
                        })
                    });
                } else {
                    return new Style({
                        image: new Circle({
                            fill: new Fill({
                                color: form.styling.fillColor
                                    ? form.styling.fillColor
                                    : 'rgba(244, 148, 65, 0.3)'
                            }),
                            stroke: new Stroke({
                                color: form.styling.lineColor
                                    ? form.styling.lineColor
                                    : '#f49441',
                                width: form.styling.lineWidth
                                    ? form.styling.lineWidth
                                    : 3
                            }),
                            radius: 5
                        }),
                        fill: new Fill({
                            color: form.styling.fillColor
                                ? form.styling.fillColor
                                : 'rgba(244, 148, 65, 0.3)'
                        }),
                        stroke: new Stroke({
                            color: form.styling.lineColor
                                ? form.styling.lineColor
                                : '#f49441',
                            width: form.styling.lineWidth
                                ? form.styling.lineWidth
                                : 3
                        })
                    });
                }
            });

            this.formService.formLayer = formMap;
            this.mapService.map().addLayer(formMap);
        }
    }

    enableDragAndDrop(): void {
        this.dragAndDropInteraction = new DragAndDrop({
            formatConstructors: [
                GeoJSON as any,
                TopoJSON as any,
                GPX as any,
                IGC as any,
                KML as any,
                MVT as any
            ],
            projection: this.mapService.projection
        });

        this.dragAndDropInteraction.on('addfeatures', event => {
            const layer = this.layerService.dragAndDropLayer();

            layer.getSource().clear();
            layer.getSource().addFeatures(event.features);

            // And zoom to the features
            this.mapService.map().getView().fit(layer.getSource().getExtent());

            this.snackBar.open(
                `${event.features.length} elementen zijn ingeladen`,
                '',
                {
                    panelClass: 'white-snackbar',
                    verticalPosition: 'top',
                    duration: 10000
                }
            );
        });

        this.mapService.map().addInteraction(this.dragAndDropInteraction);

        // @Todo add some sort of guidance for the user, below can be used
        // this.mapService.map().getViewport().addEventListener('dragover', function(event) {
        // });

        // this.mapService.map().getViewport().addEventListener('drop', function(event) {
        // });
    }

    openPdf(): void {
        this.dialog.open(UserManualDialogComponent, {
            width: '65vw',
            height: '75vh'
        });
    }

    onTabChanged(event: MatTabChangeEvent): void {
        this.sidenavService.tabIndex.set(event.index);

        if (this.tabChangeSubscription) {
            return;
        }

        this.tabChangeSubscription = this.tabGroup.animationDone.subscribe(
            () => {
                this.tabGroup.updatePagination();
            }
        );
    }

    openCalamities(): void {
        this.calamityService.showAllCalamities();
    }
}
