<ng-container *ngIf="screen">
    <h1
        class="titleContainer justify-content-between text-center align-items-center"
    >
        <a mat-button (click)="toggleFullscreen()" class="posX">
            <fa-icon [icon]="faTimes"></fa-icon>
        </a>
        <span>{{ screen.title }} </span>
    </h1>
    <div class="text-center">
        {{ screen.summary }}
    </div>
    <div class="actions">
        <ul *ngIf="!contacts">
            <li
                *ngFor="let a of sortActions(screen.actions)"
                class="text-center"
            >
                <button [routerLink]="[]" (click)="executeAction(a)">
                    {{ a.action }}
                </button>
            </li>
        </ul>

        <div class="contacts" *ngIf="contacts">
            <ul>
                <li *ngFor="let c of contacts" class="text-center">
                    <button [routerLink]="[]" (click)="call(c.value)">
                        <fa-icon class="iconPos" [icon]="faPhoneAlt"></fa-icon>
                        {{ c.name }} - {{ c.organisation }} <br />
                        {{ c.value }}
                    </button>
                </li>
                <li class="text-center">
                    <button
                        [routerLink]="[]"
                        (click)="goToScreen(contactNext)"
                        class="next"
                    >
                        Verder
                    </button>
                </li>
            </ul>
        </div>
    </div>

    <a
        mat-button
        class="back text-center"
        (click)="back()"
        *ngIf="screenHistory.length > 0"
    >
        <fa-icon [icon]="faArrowLeft"></fa-icon> Back
    </a>

    <div *ngIf="screenService.screen['id'] === 30" class="container-sm">
        <table class="table table-sm" id="quick-info-table">
            <tbody>
                <tr>
                    <td class="font-weight-bold text-size m-0">Naam</td>
                    <td class="text-size m-0">
                        {{ calamityService.quickInfo.name }}
                    </td>
                </tr>
                <tr>
                    <td class="font-weight-bold text-size m-0">Organisatie</td>
                    <td class="text-size m-0">
                        {{ calamityService.quickInfo.organisation }}
                    </td>
                </tr>
                <tr>
                    <td class="font-weight-bold text-size">Notities</td>
                    <td class="text-size">
                        {{ calamityService.quickInfo.notes }}
                    </td>
                </tr>
                <tr>
                    <td class="font-weight-bold text-size">
                        Wegnummeromschrijving
                    </td>
                    <td class="text-size">
                        {{ calamityService.quickInfo.wegnummeromschrijving }}
                    </td>
                </tr>
                <tr>
                    <td class="font-weight-bold text-size">Traject</td>
                    <td class="text-size">
                        {{ calamityService.quickInfo.traject }}
                    </td>
                </tr>
                <tr>
                    <td class="font-weight-bold text-size">Beheerder</td>
                    <td class="text-size">
                        {{ calamityService.quickInfo.beheerder }}
                    </td>
                </tr>
                <tr>
                    <td class="font-weight-bold text-size">Gemeente</td>
                    <td class="text-size">
                        {{ calamityService.quickInfo.gemeente }}
                    </td>
                </tr>
                <tr>
                    <td class="font-weight-bold text-size">Gebiedsnaam</td>
                    <td class="text-size">
                        {{ calamityService.quickInfo.gebiedsnaam }}
                    </td>
                </tr>
                <tr>
                    <td class="font-weight-bold text-size">Rayon</td>
                    <td class="text-size">
                        {{ calamityService.quickInfo.rayon }}
                    </td>
                </tr>
                <tr>
                    <td class="font-weight-bold text-size">Hoofdaannemer</td>
                    <td class="text-size">
                        {{ calamityService.quickInfo.hoofdaannemer }}
                    </td>
                </tr>
                <tr>
                    <td class="font-weight-bold text-size">
                        Onderhoudsplichtige
                    </td>
                    <td class="text-size">
                        {{ calamityService.quickInfo.onderhoudsplichtige }}
                    </td>
                </tr>
                <tr>
                    <td class="font-weight-bold text-size">
                        Vra_plaatsingsdatum_huidig
                    </td>
                    <td class="text-size">
                        {{
                            calamityService.quickInfo.vra_plaatsingsdatum_huidig
                        }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-container>
