import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subject } from 'rxjs';
import * as localforage from 'localforage';

import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CalamityService {
    quickInfo: any;
    allCalamities: any;
    calamities = [];
    activeCalamity: any;
    _contact: boolean;
    contactChange: Subject<any> = new Subject();
    fullscreen = false;
    empty = false;

    location: any;
    toggleComponentChange: Subject<void> = new Subject();

    constructor(private readonly http: HttpClient) {}

    findCalamities() {
        this.calamities = [];

        let finished = false;
        this.http
            .get(
                `${environment.api_base_url}/calamities/location/${this.location}`,
                { responseType: 'json' }
            )
            .subscribe((response: any) => {
                this.quickInfo = response.contacts;
                if (!response.calamities.length) {
                    finished = true;
                    this.toggleComponent();
                }
                if (!finished) {
                    response.calamities.forEach(id => {
                        const cal = this.allCalamities.find(c => c.id === id);
                        if (cal) {
                            this.calamities.push(cal);
                        }
                    });

                    this.toggleComponent();
                    finished = true;
                }
            });

        // The request gets 5 seconds otherwise it shows themall
        setTimeout(() => {
            if (!finished) {
                localforage.getItem('calamities').then((value: any) => {
                    this.calamities = value;

                    this.toggleComponent();
                    finished = true;
                });
            }
        }, 5000);
    }

    showAllCalamities() {
        this.calamities = this.allCalamities;
        this.toggleComponent();
    }

    set contact(newContact: boolean) {
        this._contact = newContact;
        this.contactChange.next(this._contact);
    }

    get contact(): boolean {
        return this._contact;
    }

    getContacts() {
        return this.http.get(environment.api_base_url + '/contacts', {
            responseType: 'json'
        });
    }

    /**
     * Toggles CalamityComponent to open (CalamityComponent@toggleCalamities)
     */
    toggleComponent(): void {
        this.toggleComponentChange.next();
    }

    toggleCalamities() {
        this.fullscreen = !this.fullscreen;

        // When not in fullscreen mode, we're disabled, unset the activeCalamity
        if (!this.fullscreen) {
            this.activeCalamity = undefined;
        }
    }
}
