<div class="account">
    <button
        mat-mini-fab
        [matMenuTriggerFor]="accountMenu"
        color="secondary"
        matTooltip="Kies GISportaal / Uitloggen"
    >
        <fa-icon [icon]="faUser"></fa-icon>
    </button>
    <mat-menu #accountMenu="matMenu" [overlapTrigger]="false">
        <button
            mat-menu-item
            *ngIf="authService.configurations()?.length > 1"
            [matMenuTriggerFor]="configs"
        >
            Configuraties
        </button>

        <button
            mat-menu-item
            *ngIf="configService.config()?.dashboards?.length"
            [matMenuTriggerFor]="dashboards"
        >
            <span>Dashboards</span>
        </button>

        <!-- Usermanual -->
        <button
            mat-menu-item
            *ngIf="configService.config()?.options?.usermanual"
            (click)="openPdf()"
            class="manualButton"
            matToolTip="Handleiding"
        >
            <span>Handleiding</span>
        </button>

        <!-- Admin Panel -->
        <button
            mat-menu-item
            *ngIf="authService.hasPermission(['manager', 'admin'])"
            [routerLink]="['/', 'admin', 'users', 'manage']"
        >
            Beheeromgeving
        </button>

        <!-- Temporary if for settings if someone has no acces to customextent -->
        <!-- Settings -->
        <button
            mat-menu-item
            *ngIf="configService.config()"
            (click)="openSettings()"
            class="manualButton"
        >
            <span>Instellingen</span>
        </button>

        <button
            mat-menu-item
            *ngIf="
                configService.config()?.tools?.notifications?.receiver &&
                checkApprovement(
                    configService.config()?.tools?.notifications?.receiver
                )
            "
            (click)="openApprovements()"
        >
            Accorderen
        </button>
        <button
            mat-menu-item
            *ngIf="environment.loginPrefix === 'prvgld_'"
            (click)="showContacts()"
        >
            Telefoonboek
        </button>
        <!-- Contact Screen for Gelderland -->

        <!-- Account -->
        <button mat-menu-item [matMenuTriggerFor]="account">Account</button>
    </mat-menu>
    <mat-menu #configs="matMenu" [overlapTrigger]="false">
        <button
            mat-menu-item
            *ngFor="let c of authService.configurations()"
            (click)="loadConfiguration(c)"
        >
            {{ c.name }}
        </button>
    </mat-menu>
    <mat-menu #account="matMenu" [overlapTrigger]="false">
        <button mat-menu-item (click)="openChangePassword()">
            Wachtwoord Wijzigen
        </button>
        <button mat-menu-item (click)="open2FA()">2FA</button>
        <button mat-menu-item (click)="openLink()">Privacy verklaring</button>
        <button
            mat-menu-item
            *ngIf="configService.config()?.tools?.notifications"
            (click)="openNotifications()"
        >
            Melding maken
        </button>
        <button mat-menu-item (click)="logout()">Uitloggen</button>
    </mat-menu>
    <mat-menu #dashboards="matMenu" [overlapTrigger]="false">
        <button
            mat-menu-item
            *ngFor="let c of configService.config().dashboards"
            (click)="loadDashboard(c)"
        >
            {{ c.name }}
        </button>
    </mat-menu>
</div>
