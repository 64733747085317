import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { CalamityService } from 'app/_services';

import {
    faTimes,
    faAngleRight,
    faAngleDown,
    faPhoneAlt,
    faSearch,
    faEnvelope
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    // fontawsome icons
    readonly faTimes = faTimes;
    readonly faAngleRight = faAngleRight;
    readonly faAngleDown = faAngleDown;
    readonly faPhoneAlt = faPhoneAlt;
    readonly faSearch = faSearch;
    readonly faEnvelope = faEnvelope;

    oneCategory: any[] = [];
    MultiDimensionalArray: [][] = [];

    selected: any;

    getFilterContacts: any = <HTMLElement[]>(
        (<any>document.getElementsByClassName('filterContacts'))
    );
    getFilterContactsClass: any = <HTMLElement[]>(
        (<any>document.getElementsByClassName('filterContactsClass'))
    );

    fullscreen: boolean;
    contacts: any[];
    filterContacts: any[] = [];
    contactenTelefoonlijst: any[] = [];
    sortFilterContacts: any[] = [];
    categoryStatus: boolean[] = [];

    public icon = 'keyboard_arrow_right';

    constructor(
        private readonly calamityService: CalamityService,
        private readonly cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.calamityService.contactChange.subscribe(value => {
            this.fullscreen = true;
        });

        // Get the contacts
        this.calamityService.getContacts().subscribe((response: any) => {
            this.contacts = response;

            var category = new Array();

            for (var j = 0; j < this.contacts.length; j++) {
                category[j] = this.contacts[j].category.category; // hier worden alle categorieën gefilterd en in de array 'category' gezet.
                this.contactenTelefoonlijst[j] = this.contacts[j].organisation;
            }

            for (var i in category) {
                if (this.oneCategory.indexOf(category[i]) < 0) {
                    this.oneCategory.push(category[i]); // hier wordt van alle dubbele category 1 category gemaakt zodat er niet bijvoorbeeld 'Aannemer' meerdere keren weergeven word.
                    this.oneCategory.sort((one, two) => (one < two ? -1 : 1));
                } else {
                    //deze categorie is al gevonden. Hier hoeft niks te komen.
                }
            }

            for (var l = 0; l < this.oneCategory.length; l++) {
                // hier word gekeken naar welke categorie het is.
                var openClose = false; // Hier word alles op false gezet zodat we die in de functie true kunnen maken en het pijl icoontje laten veranderen.
                this.categoryStatus[l] = openClose;
                for (var k = 0; k < this.contacts.length; k++) {
                    // Hier wordt gekeken naar welk contact er bij de juiste categorie hoort.
                    if (
                        this.oneCategory[l] ===
                        this.contacts[k].category.category
                    ) {
                        // als de category hetzelfde is ald de contact categorie dan...
                        this.MultiDimensionalArray.push(this.contacts[k]); // Zet hij de contacten in de MultiDimensionalArray.
                    }
                }
                this.filterContacts[l] = this.MultiDimensionalArray; // hier wordt de filterContacts hetzelfde als de MultiDimensionalArray.
                // this.phone.push(this.filterContacts[l].type_id);
                this.sortFilterContacts.push(
                    this.filterContacts[l].sort((one, two) =>
                        one.organisation < two.organisation ? -1 : 1
                    )
                ); // sorteer alle organisations op alfabetische volgorde.
                this.MultiDimensionalArray = []; // Hier maken we de Array leeg.
            }
        });

        this.cdr.detectChanges();
    }

    onSelect(content: string) {
        this.selected = content.toLowerCase();
        // console.log(this.test, 'de test');
        return this.selected;
    }
    zoekResult() {
        let input = this.selected;

        for (let i = 0; i < this.getFilterContacts.length; i++) {
            if (
                !this.getFilterContacts[i].innerHTML
                    .toLowerCase()
                    .includes(input)
            ) {
                this.getFilterContacts[i].style.display = 'none';
            } else {
                this.getFilterContacts[i].style.display = 'inline-block';
            }

            if (input.length <= 2) {
                this.getFilterContacts[i].style.display = 'none';
            }
            if (input.length == 0) {
                this.getFilterContacts[i].style.display = 'inline-block';
            }
        }

        for (let i = 0; i < this.getFilterContactsClass.length; i++) {
            if (
                !this.getFilterContactsClass[i].innerHTML
                    .toLowerCase()
                    .includes(input)
            ) {
                this.getFilterContactsClass[i].style.display = 'none';
            } else {
                this.getFilterContactsClass[i].style.display = 'inline-block';
            }

            if (input.length == 0) {
                this.getFilterContactsClass[i].style.display = 'none';
            }
        }
    }

    toggleFullscreen() {
        this.fullscreen = !this.fullscreen;
    }

    contact(contact) {
        if (contact.type.type === 'phone') {
            window.open('tel:' + contact.value);
        } else {
            window.open('mailto:' + contact.value);
        }

        this.cdr.detectChanges();
    }

    DropDown(i) {
        var tag = document.getElementById('filterContacts' + i);
        tag.style.display = tag.style.display === 'block' ? 'none' : 'block'; // hier wordt de contactlijst weergeven als er op de categorie geklikt wordt. In de css staat hij altijd op 'display: none;' om he tin het begin niet te laten zien.
    }

    toggleSearch() {
        var tag = document.getElementById('search');
        tag.style.display = tag.style.display === 'block' ? 'none' : 'block'; // hier wordt de contactlijst weergeven als er op de categorie geklikt wordt. In de css staat hij altijd op 'display: none;' om he tin het begin niet te laten zien.

        (<HTMLInputElement>document.getElementById('search')).value = '';
        if (document.getElementById('search').style.display == 'none') {
            for (let i = 0; i < this.getFilterContacts.length; i++) {
                this.getFilterContacts[i].style.display = 'inline-block';
            }
            for (let i = 0; i < this.getFilterContactsClass.length; i++) {
                this.getFilterContactsClass[i].style.display = 'none';
            }
        }

        if (window.innerWidth <= 600) {
            var displayTitel = document.getElementById('titel');
            displayTitel.style.display =
                displayTitel.style.display === 'none' ? 'block' : 'none';
        }
    }

    toggleCollapse(i): void {
        this.categoryStatus[i] = !this.categoryStatus[i]; // als toggleCollapse true is word hij weer terug naar false gezet
    }
}
