import {
    Component,
    OnInit,
    OnDestroy,
    ChangeDetectorRef
} from '@angular/core';

import { ScreenService, CalamityService } from 'app/_services';

import { faArrowLeft, faPhoneAlt, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'screen',
    templateUrl: './screen.component.html',
    styleUrls: ['./screen.component.scss']
})
export class ScreenComponent implements OnInit, OnDestroy {
    readonly faArrowLeft = faArrowLeft;
    readonly faPhoneAlt = faPhoneAlt;
    readonly faTimes = faTimes;

    screen: any; // The active screen
    screenChange: any;
    screenHistory: number[] = [];

    contacts: any;
    contactNext: number;

    constructor(
        public readonly calamityService: CalamityService,
        public readonly screenService: ScreenService,
        private readonly cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        // Update the screen when the screenService is updated
        this.screenService.screenChange.subscribe(value => {
            this.screen = value;
            if (value) {
                this.screenHistory.push(this.screen.id);
            }
        });

        this.cdr.detectChanges();
    }

    ngOnDestroy() {
        this.screenService.screen = undefined;
    }

    executeAction(action): void {
        // If there are contacts, make them call before continueing
        if (action.contacts.length > 0) {
            let contacts = action.contacts;

            // Get an updated list by location, if we have one
            if (navigator.onLine && this.calamityService.location) {
                this.screenService
                    .getActionContactsByLocation(
                        action.id,
                        this.calamityService.location
                    )
                    .then((value: any) => {
                        contacts = value;
                        this.contacts = contacts;

                        this.cdr.detectChanges();
                    });
            } else {
                // if something goes wrong get all the contacts
                this.contacts = contacts;
            }

            this.contactNext = action.next;

            // If there is only one contact, immediately call them, otherwise, show a list
            // if(contacts.length === 1) {
            //    this.call(contacts[0].value);
            //} else {

            //}
        } else if (action.next !== undefined && action.next !== null) {
            this.calamityService.activeCalamity.screens.find(
                s => s.id === action.next
            );

            // Skip if there is only one option for calling
            // if (screen.actions[0].contacts.length) {
            //     this.screenService.getActionContactsByLocation(screen.actions[0].id, this.calamityService.location).then((value: any) => {
            //         this.contacts = value;
            //     });

            //     this.goToScreen(action.next);

            //     this.contactNext = screen.actions[0].next;
            // } else {
            // }
            this.goToScreen(action.next);
        } else {
            // Reset the location, so it won't be used again for the next calamity
            this.calamityService.location = undefined;

            this.emptyScreens();
            this.calamityService.toggleCalamities();
        }

        this.cdr.detectChanges();
    }

    call(phonenumber) {
        window.open('tel:' + phonenumber);
    }

    goToScreen(screenId: number): void {
        // Clear contacts if necessary
        if (this.contacts) {
            this.contacts = undefined;
            this.contactNext = undefined;
        }

        const screen = this.calamityService.activeCalamity.screens.find(
            s => s.id === screenId
        );

        if (screen) {
            this.screenService.screen = screen;
        } else {
            this.emptyScreens();
            this.calamityService.toggleCalamities();
        }
        this.cdr.detectChanges();
    }

    sortActions(array) {
        return array.sort((a, b) => {
            return a.order - b.order;
        });
    }

    /**
     * Loads the previous screen
     */
    back(): void {
        // Get the previous screen, remove the last two elements of the history (previousScreen and currentScreen)
        if (this.screenHistory.length > 1) {
            let previousScreen;
            if (this.contacts) {
                // Show the actions again by emptying the contacts
                this.contacts = undefined;
                previousScreen =
                    this.screenHistory[this.screenHistory.length - 1];
            } else {
                previousScreen = this.screenHistory.splice(-2, 2)[0];
            }

            this.contacts = false;
            const screen = this.calamityService.activeCalamity.screens.find(
                s => s.id === previousScreen
            );
            // if (screen.actions[0].contacts.length) {
            //     this.screenService.getActionContactsByLocation(screen.actions[0].id, this.calamityService.location).then((value: any) => {
            //         this.contacts = value;
            //     });
            // }
            this.screenService.screen = screen;
        } else {
            this.emptyScreens();
        }

        this.cdr.detectChanges();
    }

    // Reset this component by setting the screen to undefined
    emptyScreens(): void {
        this.screenHistory = [];
        this.screenService.screen = undefined;
    }

    toggleFullscreen() {
        this.calamityService.fullscreen = !this.calamityService.fullscreen;
    }
}
