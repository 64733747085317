import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CalamityService, ScreenService } from 'app/_services';

import { faSignOut, faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'calamity',
    templateUrl: './calamity.component.html',
    styleUrls: ['./calamity.component.scss']
})
export class CalamityComponent implements OnInit {
    readonly faSignOut = faSignOut;
    readonly faArrowLeft = faArrowLeft;
    readonly faTimes = faTimes;

    _featuresChangeSubscription: any;
    _toggleCalamitiesSubscription: any;

    constructor(
        public readonly calamityService: CalamityService,
        public readonly screenService: ScreenService,
        public readonly http: HttpClient,
        private readonly cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this._toggleCalamitiesSubscription =
            this.calamityService.toggleComponentChange.subscribe(response => {
                this.calamityService.empty = false;

                if (!this.calamityService.calamities.length) {
                    this.calamityService.empty = true;
                }
                this.calamityService.toggleCalamities();

                this.cdr.detectChanges();
            });
    }

    startCalamity(calamity: any) {
        // Set the active calamity, so it can be used by other components
        this.calamityService.activeCalamity = calamity;

        // Activate the first screen of the calamity, triggers the ScreenComponent
        this.screenService.screen =
            this.calamityService.activeCalamity.screens.find(
                s => s.id === calamity.screen_id
            );

        this.cdr.detectChanges();
    }

    toggleCalamities(): void {
        this.calamityService.toggleCalamities();
        this.cdr.detectChanges();
    }

    toggleFullscreen() {
        this.calamityService.fullscreen = !this.calamityService.fullscreen;
    }
}
