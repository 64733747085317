import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ScreenService {
    _screen;
    screenChange: Subject<any> = new Subject();
    screenContacts: any[];

    constructor(private http: HttpClient) {}

    set screen(newScreen: any[]) {
        this._screen = newScreen;
        this.screenChange.next(this._screen);
    }

    get screen(): any[] {
        return this._screen;
    }

    /**
     * Get all contacts for an action by location
     * @param  action   The action id
     * @param  location Location we are on
     * @return          A list of contacts
     */
    async getActionContactsByLocation(
        action: number,
        location: string
    ): Promise<any> {
        await this.http
            .get(
                `${environment.api_base_url}/calamities/contacts/${action}/location/${location}`,
                { responseType: 'json' }
            )
            .pipe(
                map((response: any[]) => {
                    this.screenContacts = response;
                })
            )
            .toPromise();

        return this.screenContacts;
    }
}
